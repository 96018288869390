
import { ref, defineComponent, onMounted, computed } from 'vue';
import { myStore } from '@/store/Store';
import { UrlParser } from './url-parser';
import HomePage from './pages/HomePage.vue';
import SongPage from './pages/SongPage.vue';
import PageNotFoundView from './pages/PageNotFound.vue';
import NavbarComponent from './components/NavbarComponent.vue';
import DebugComponentVue from './components/DebugComponent.vue';
import Page from './classes/Page';

export default defineComponent({
  name: 'Librarist',
  components: {
    navbar : NavbarComponent,
    debug: DebugComponentVue,

    SongPage, HomePage, PageNotFoundView
  },
  setup() {
    const store = myStore();
    
    const page = ref(null);
    const component = computed(() => `${page.value}Page`);
    const showSearchInNavbar = computed(() => page.value != "Search" && page.value != "Home");
    const debug = false;

    onMounted(() => {
      const parser = new UrlParser();

      if (parser.page) {
        switch(parser.page) {
          case Page.HOME:
            store.dispatch('loadSongs');
            page.value = "Home";
            return;
          case Page.SONG:
            store.dispatch('loadSong', {artist: parser.artist, song: parser.song });
            page.value = "Song";
            return;
        }
      }
    });

    return { page, component, showSearchInNavbar, debug }
  }
});
