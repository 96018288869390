<template>
  <div class="chords d-flex">
    <div v-for="(chord, index) in chords" :key="index">
      <chord type="image" :chord="chord"></chord>
    </div>
  </div>  
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from 'vue';
import { Chord } from '@/classes/Chord'
import ChordComponent from '@/components/ChordComponent.vue'

export default defineComponent({
  name: 'ChordsComponent',
  components: { chord: ChordComponent },
  props: {
    chords: Object as PropType<Array<Chord>>,
  },
  setup(props) {
    return {  }
  }
});
</script>

<style scoped>
.chords {
  justify-content: center;
  margin: 2em 0;
}
</style>
