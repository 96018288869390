import URL from 'url-parse';
import Page from '@/classes/Page';

export class UrlParser {
    public page: Page;
    public artist: string;
    public song: string;

    constructor() {
        const url = new URL(window.document.URL, true);
        // this.uri = url.pathname + url.query;
        let arr;

        
        if (!this.page) {
            arr = url.pathname.match("/song/([a-z0-9-]+)/([a-z0-9-]+)$");
            if (arr && arr.length == 3) {
                this.page = Page.SONG;
                this.artist = arr[1]
                this.song = arr[2]
            }
        }

        if (!this.page) {
            arr = url.pathname.match("/$");
            if (arr && arr.length == 1) {
                this.page = Page.HOME;
            }
        }

        if (!this.page) this.page = Page.NOT_FOUND;
    }
}