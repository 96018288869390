<template>
  <div>
    <ul class="list-unstyled">
      <li v-for="(song, index) in songs" :key="index"> 
        <a :href="link(song)">{{ song.artist }} - {{ song.name }}</a>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import { myStore } from '@/store/Store'
import { Song } from '@/classes/Song';

export default defineComponent({
  name: 'HomePage',
  components: {
  },
  setup() {
    const store = myStore();
    
    const songs = computed(() => store.getters.songs);

    function link(song: Song) {
      return `/song/${song.path}`;
    }

    return { songs, link }
  }
});
</script>

<style scoped>
</style>