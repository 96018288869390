
/* eslint-disable vue/no-unused-components */
import { defineComponent, computed } from 'vue';
import { myStore } from '@/store/Store'
import VRuntimeTemplate from "vue3-runtime-template";
import ChordComponent from '@/components/ChordComponent.vue'
import ChordsComponent from '@/components/ChordsComponent.vue';

export default defineComponent({
  name: 'SongPage',
  components: { 
    VRuntimeTemplate, 
    chord: ChordComponent,
    chords: ChordsComponent
  },
  setup() {
    const store = myStore();
    
    const song = computed(() => store.getters.song);
    const html = computed(() => {
      const template = store.getters.song.html.replaceAll(/(<div is="chord")(.*?)(<\/div>)/g, "<chord$2</chord>");
      return `<div class="tab">${template}</div>`;
    });

    return { song, html }
  }
});
