<template>
  <nav class="navbar navbar-expand navbar-dark bg-dark mb-4">
    <div class="container">

      <a class="navbar-brand" href="/">Riff NZ</a>


      <button class="navbar-toggler ms-auto" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

    </div>
  </nav>
</template>

<script lang="ts">
import { myStore } from '@/store/Store';
import { defineComponent, computed, ref } from 'vue';

export default defineComponent({
  name: 'NavbarComponent',
  props: {
    showSearch: Boolean
  },
  setup(props) {
    const store = myStore();

    return {  }
  },
});
</script>

<style lang="scss" scoped>
.flag {
  font-size: 1.0em;
}

.flag .flag-icon {
  margin-left: 4px;
}

.navbar-brand {
  margin-right: 0;
}

.navbar-nav .nav-link.flag {
  font-size: 1.25em;
  padding-left: 0.3rem;
}
</style>