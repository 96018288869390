
import { defineComponent, computed, PropType } from 'vue';
import { Chord } from '@/classes/Chord'
import ChordComponent from '@/components/ChordComponent.vue'

export default defineComponent({
  name: 'ChordsComponent',
  components: { chord: ChordComponent },
  props: {
    chords: Object as PropType<Array<Chord>>,
  },
  setup(props) {
    return {  }
  }
});
