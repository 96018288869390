<template>
  <div v-if="song">
    <chords :chords="song.chords"></chords>
    <v-runtime-template :template="html" />
  </div>
</template>

<script lang="ts">
/* eslint-disable vue/no-unused-components */
import { defineComponent, computed } from 'vue';
import { myStore } from '@/store/Store'
import VRuntimeTemplate from "vue3-runtime-template";
import ChordComponent from '@/components/ChordComponent.vue'
import ChordsComponent from '@/components/ChordsComponent.vue';

export default defineComponent({
  name: 'SongPage',
  components: { 
    VRuntimeTemplate, 
    chord: ChordComponent,
    chords: ChordsComponent
  },
  setup() {
    const store = myStore();
    
    const song = computed(() => store.getters.song);
    const html = computed(() => {
      const template = store.getters.song.html.replaceAll(/(<div is="chord")(.*?)(<\/div>)/g, "<chord$2</chord>");
      return `<div class="tab">${template}</div>`;
    });

    return { song, html }
  }
});
</script>

<style>
h1, h2, h3 {
  text-align: center;
}
.section {
  font-size: 16px;
  padding: 0 2em 1em 2em;
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;  
}

.line {
  padding-top: 1.2em;
  position: relative;
}

.line .chords {
  position: absolute;
  display: inline-block;
  top: 0;
  font-weight: bold;
}

.line .chords .chord {
  padding-right: 0.5em;
}

.tab {
  -webkit-columns: 3 500px;
  -moz-columns: 3 500px;
  columns: 3 500px;
}
</style>