<template>
  <div class="chord">
    <img v-if="image" :src="url">
    <span v-else>{{ name }}</span>
  </div>  
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from 'vue';
import { Chord } from '@/classes/Chord'

export default defineComponent({
  name: 'ChordComponent',
  components: { },
  props: {
    chord: Object as PropType<Chord>,
    name: String,
    type: String
  },
  setup(props) {
    const fingers = new Map();
    fingers.set("x", "-");
    fingers.set("1", "T");
    fingers.set("2", "1");
    fingers.set("3", "2");
    fingers.set("4", "3");
    fingers.set("5", "4");

    const image = computed(() => props.type === 'image');
    const url = computed(() => {
      let link = 'http://chordgenerator.net/';
      link += encodeURIComponent(props.chord.name);
      link += '.png?s=2';
      link += '&p=';
      link += props.chord.strings.split(' ').join('-');
      if (props.chord.fingers) {
        link += '&f=';
        link += props.chord.fingers.split(' ').map((item) => {
          if (fingers.has(item)) return fingers.get(item);
          return item;
        }).join('');
      }
      return link;
    });

    return { image, url }
  }
});
</script>

<style scoped>
.chord {
  display: inline-block;
}
</style>
