
import { defineComponent, computed, watch } from 'vue';
import { myStore } from '@/store/Store'

export default defineComponent({
  name: 'DebugComponent',
  components: {
  },
  setup() {
    const store = myStore();
    const state = computed(() => store.state);

    // const s = computed(() => ...mapState('module/search', 'search'));

  /*watch(state.value, (before, after) => {
    // console.log(store);
  });*/

    return { state }
  }


});
