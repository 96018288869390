
import { myStore } from '@/store/Store';
import { defineComponent, computed, ref } from 'vue';

export default defineComponent({
  name: 'NavbarComponent',
  props: {
    showSearch: Boolean
  },
  setup(props) {
    const store = myStore();

    return {  }
  },
});
