import { Song } from "@/classes/Song";
import axios from "axios"

class Api {
  public async getSongs(): Promise<Array<Song>> {
    const response = await axios.get("https://raw.githubusercontent.com/riff-nz/api/master/songs/index.json");
    return response.data
  }

  public async getSong(artist: string, song: string): Promise<Song> {
    const response = await axios.get(`https://raw.githubusercontent.com/riff-nz/api/master/songs/${artist}/${song}.json`);
    return response.data
  }
}

export const api = new Api();