
import { defineComponent, computed } from 'vue';

export default defineComponent({
  name: 'BookView',
  components: {
  },
  setup() {

    return {  }
  }
});
