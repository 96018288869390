
import { defineComponent, computed } from 'vue';
import { myStore } from '@/store/Store'
import { Song } from '@/classes/Song';

export default defineComponent({
  name: 'HomePage',
  components: {
  },
  setup() {
    const store = myStore();
    
    const songs = computed(() => store.getters.songs);

    function link(song: Song) {
      return `/song/${song.path}`;
    }

    return { songs, link }
  }
});
