import { createApp } from 'vue'
import App from './App.vue'
import { createHead } from '@vueuse/head'
import { store, key, } from '@/store/Store'
import FlagIcon from 'vue-flag-icon'

import 'bootstrap'
import "./assets/scss/custom.scss";
import { library } from '@fortawesome/fontawesome-svg-core'
import { faShoppingCart, faSyncAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faShoppingCart, faSyncAlt)

const app = createApp(App);
const head = createHead();

app.component('font-awesome-icon', FontAwesomeIcon)
app.use(store, key);
app.use(head);
app.use(FlagIcon);

app.mount('#app');
