import { api } from '@/classes/Api';
import { InjectionKey } from 'vue'
import { createStore, useStore as baseUseStore, Store } from 'vuex'
import { Song } from '../classes/Song';

export interface State {
  songs: Array<Song>;
  song: Song;
}

// define injection key
export const key: InjectionKey<Store<State>> = Symbol()

export const store = createStore<State>({
  state: {} as State,
  getters: {
    songs(state) {
      return state.songs;
    },
    song(state) {
      return state.song;
    }
  },
  mutations: {
    setSongs(state, songs) {
      state.songs = songs;
    },
    setSong(state, song) {
      state.song = song;
    }
  },
  actions: {
    loadSongs: async ({ commit }) => {
      const songs = await api.getSongs();
      commit("setSongs", songs);
    },
    loadSong: async ({ commit }, { artist, song}) => {
      const s = await api.getSong(artist, song);
      commit("setSong", s);
    },
  }
})

export function myStore() {
  return baseUseStore(key)
}